import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import update from "immutability-helper";
import { handleBeforeUnload } from "..";
import FmcFullWidthButton from "../../../Mechanic/components/FmcFullWidthButton";
import ServiceList from "../../../Mechanic/Estimates/components/ServiceList";
import { callApiV2 } from "../../../../_apis/api_base";

const EstimateFormContext = React.createContext({
  appointment: null,
  data: null,
  onUpdateServiceField: null,
  onAddService: null,
  onRemoveService: null,
  onUpdatePartField: null,
  onAddPart: null,
  onRemovePart: null,
  onUpdateField: null,
  openConfirm: null,
  isAdmin: true,
  repeatCustomer: null,
  onSave: null,
  isFleet: null,
  isManager: null,
});

const EstimateForm = ({
  initialData,
  appointment,
  isSubmitting,
  onSubmit,
  setAppointment,
  isManager,
  admin_user_id,
}) => {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState([]);
  const [discountError, setDiscountError] = useState("");
  const [coupon, setCoupon] = useState(
    appointment.coupon_used ? appointment.coupon_used.code : null
  );
  const [couponError, setCouponError] = useState();

  useEffect(() => setFormData(initialData), [initialData]);

  const isValid = useMemo(() => {
    const formErrors = [];

    (formData.services || []).forEach((service) => {
      if (Object.keys(service).length === 1 && service.id) {
        return;
      }

      const name = service.name || "a service";
      if (!service.name) {
        formErrors.push(`Missing name for ${name}`);
      } else {
        const index = formErrors.indexOf(`Missing name for ${name}`);
        if (index > -1) {
          formErrors.splice(index, 1);
        }
      }

      if (!service.labor_hours && !service.free_member_service) {
        formErrors.push(`Missing labor hours for ${name}`);
      } else {
        const index = formErrors.indexOf(`Missing labor hours for ${name}`);
        if (index > -1) {
          formErrors.splice(index, 1);
        }
      }

      (service.parts || []).forEach((part) => {
        if (Object.keys(part).length === 1 && part.id) {
          return;
        }

        if (!part.name) {
          formErrors.push("Missing name for a part");
        } else {
          const index = formErrors.indexOf("Missing name for a part");
          if (index > -1) {
            formErrors.splice(index, 1);
          }
        }

        const partName = part.name || "a part";
        if (!part.quantity) {
          formErrors.push(`Missing quantity for ${partName}`);
        } else {
          const index = formErrors.indexOf(`Missing quantity for ${partName}`);
          if (index > -1) {
            formErrors.splice(index, 1);
          }
        }

        if (
          (!part.price || part.price == 0.0) &&
          !service.free_member_service &&
          !service.is_canned_service
        ) {
          formErrors.push(`Missing price for ${partName}`);
        } else {
          const index = formErrors.indexOf(`Missing price for ${partName}`);
          if (index > -1) {
            formErrors.splice(index, 1);
          }
        }

        if (
          (!part.part_number || part.part_number == "") &&
          !service.free_member_service &&
          !service.is_canned_service
        ) {
          formErrors.push(`Missing part number for ${partName}`);
        } else {
          const index = formErrors.indexOf(
            `Missing part number for ${partName}`
          );
          if (index > -1) {
            formErrors.splice(index, 1);
          }
        }
      });
    });

    setErrors(formErrors);

    const isThisValid = formData.services && formErrors.length == 0;

    if (isThisValid) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }
    return isThisValid;
  }, [formData]);

  const handleUpdateFormField = useCallback((field, value) => {
    if (field === "discount") {
      if (value !== "" && parseFloat(value) > 60 && !isManager) {
        setDiscountError("Discount cannot be greater than $60");
        setFormData((data) =>
          update(data, {
            [field]: { $set: "" },
          })
        );

        return;
      } else {
        setDiscountError("");
      }
    }

    setFormData((data) =>
      update(data, {
        [field]: { $set: value },
      })
    );
  }, []);

  const handleUpdateServiceField = useCallback((serviceId, field, value) => {
    setFormData((data) =>
      update(data, {
        services: {
          [serviceId]: {
            [field]: { $set: value },
          },
        },
      })
    );
  }, []);

  const handleAddService = useCallback(() => {
    setFormData((data) => {
      if (data.services) {
        return update(data, {
          services: { $push: [{}] },
        });
      }

      return update(data, {
        services: { $set: [{}] },
      });
    });
  }, []);

  const handleRemoveService = useCallback(
    (serviceId) => {
      if (formData.services[serviceId].id) {
        setFormData((data) =>
          update(data, {
            services: {
              [serviceId]: (service) => ({ id: service.id }),
            },
          })
        );
      } else {
        setFormData((data) =>
          update(data, {
            services: { $splice: [[serviceId, 1]] },
          })
        );
      }
    },
    [formData]
  );

  const handleUpdatePartField = useCallback(
    (serviceId, partId, field, value) => {
      setFormData((data) =>
        update(data, {
          services: {
            [serviceId]: {
              parts: {
                [partId]: {
                  [field]: { $set: value },
                },
              },
            },
          },
        })
      );
    },
    []
  );

  const handleAddPart = useCallback((serviceId) => {
    setFormData((data) => {
      if (data.services[serviceId].parts) {
        return update(data, {
          services: {
            [serviceId]: {
              parts: { $push: [{}] },
            },
          },
        });
      }

      return update(data, {
        services: {
          [serviceId]: {
            parts: { $set: [{}] },
          },
        },
      });
    });
  }, []);

  const handleRemovePart = useCallback(
    (serviceId, partId) => {
      if (formData.services[serviceId].parts[partId].id) {
        setFormData((data) =>
          update(data, {
            services: {
              [serviceId]: {
                parts: {
                  [partId]: (part) => ({ id: part.id }),
                },
              },
            },
          })
        );
      } else {
        setFormData((data) =>
          update(data, {
            services: {
              [serviceId]: {
                parts: { $splice: [[partId, 1]] },
              },
            },
          })
        );
      }
    },
    [formData]
  );

  const getLabor = (service, rate) => {
    if (service.labor_hours === undefined || !rate) {
      return "";
    } else if (service.free_member_service) {
      return 0;
    } else if (
      (service.labor_price &&
        service.name &&
        service.name.toLowerCase().includes("oil change")) ||
      service.is_canned_service
    ) {
      return service.labor_price;
    }

    return (service.labor_hours * rate).toFixed(2);
  };

  const handleSave = useCallback(() => {
    const laborRate = appointment.hourly_rate;

    const submitData = {
      ...formData,
      services: undefined,
      estimate_services_attributes: formData.services.map((service) => {
        return {
          ...service,
          labor_price: getLabor(service, laborRate),
          parts: undefined,
          parts_attributes: service.parts,
          sold_by_admin_user_id: admin_user_id,
        };
      }),
    };
    onSubmit(submitData);
  }, [onSubmit, formData]);

  return (
    <EstimateFormContext.Provider
      value={{
        appointment: appointment,
        data: formData,
        onUpdateField: handleUpdateFormField,
        onUpdateServiceField: handleUpdateServiceField,
        onAddService: handleAddService,
        onRemoveService: handleRemoveService,
        onUpdatePartField: handleUpdatePartField,
        onAddPart: handleAddPart,
        onRemovePart: handleRemovePart,
        isAdmin: true,
        repeatCustomer:
          appointment.new_customer_or_repeat_customer === "repeat_customer",
        onSave: handleSave,
        isFleet: appointment.fleet_job,
        isManager: isManager,
        isDiagToRepair: null,
      }}
    >
      <Container>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ServiceList EstimateFormContext={EstimateFormContext} />
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardContent className="">
                  <Box className="my-6">
                    <Typography className="my-6">Discount</Typography>
                    <OutlinedInput
                      type="number"
                      fullWidth
                      value={formData.discount || ""}
                      onChange={(ev) =>
                        handleUpdateFormField("discount", ev.target.value)
                      }
                    />
                    {discountError && (
                      <Typography style={{ margin: "10px" }} color="error">
                        {discountError}
                      </Typography>
                    )}
                  </Box>

                  <Box className="my-6">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onClick={() => {
                              handleUpdateFormField("pmg", !formData.pmg);
                            }}
                            checked={formData.pmg || false}
                          />
                        }
                        label="Price match guarantee"
                      />

                      {formData.pmg && (
                        <>
                          <Typography className="my-6">
                            Enter price we're matching
                          </Typography>
                          <OutlinedInput
                            type="number"
                            fullWidth
                            value={formData.pmg_price || ""}
                            onChange={(ev) =>
                              handleUpdateFormField(
                                "pmg_price",
                                ev.target.value
                              )
                            }
                          />
                        </>
                      )}
                    </FormGroup>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardContent className="">
                  <Box className="my-6">
                    <Typography className="my-6">Apply a Coupon</Typography>
                    <OutlinedInput
                      type="text"
                      fullWidth
                      value={coupon}
                      onChange={(ev) => setCoupon(ev.target.value)}
                      disabled={!!appointment.coupon_used}
                    />

                    <Button
                      style={{ margin: "10px 0" }}
                      onClick={() => {
                        callApiV2({
                          url: `/coupons/${coupon}/${appointment.id}`,
                          method: "GET",
                        }).then((res) => {
                          if (res.errors) {
                            setCouponError("Not a valid coupon");

                            setTimeout(() => {
                              setCouponError();
                            }, 4000);
                          } else {
                            setAppointment({
                              ...res.data.attributes,
                              id: appointment.id,
                            });
                          }
                        });
                      }}
                      disabled={!!appointment.coupon_used}
                      variant="outlined"
                      color="primary"
                    >
                      Validate Coupon
                    </Button>
                    {appointment.coupon_used && (
                      <Typography style={{ margin: "10px" }}>
                        Coupon applied for $
                        {appointment.coupon_used.dollars_off} off
                      </Typography>
                    )}
                    {couponError && (
                      <Typography style={{ margin: "10px" }} color="error">
                        {couponError}
                      </Typography>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Box>
                <Box>
                  {errors.length > 0 ? (
                    <Box className="my-3 text-lg">
                      <Typography>Correct the following errors</Typography>
                      <ul>
                        {[...new Set(errors)].map((i) => (
                          <li className="list-disc text-fmc-red italic m-2">
                            {i}
                          </li>
                        ))}
                      </ul>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>

                <FmcFullWidthButton
                  text="Save"
                  onClick={handleSave}
                  variant="contained"
                  disabled={isSubmitting || !isValid || errors.length > 0}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </EstimateFormContext.Provider>
  );
};

export default EstimateForm;
